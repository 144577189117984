<template>
  <CRow>
    <CModal
        :title="
        submitType === 'Update' ? $lang.stream.crud.edit : $lang.stream.crud.create
      "
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        :hide-footer="true"
        color="primary">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{ err_msg }}</CAlert>
      <CForm novalidate @submit.prevent="onSubmit">
        <CCardBody>
          <div
              class="form-group"
              :class="{ 'form-group--error': $v.stream.programmeName.$error }"
          >
            <label class="form__label"
            >{{ $lang.stream.form.programmeName }}
              <required_span/>
            </label>
            <v-select
                :options="ProgrammeModalOptions"
                v-model="stream.programmeName"
            ></v-select>
            <small
                class="error"
                v-if="$v.stream.$error && !$v.stream.programmeName.required"
            >{{ $lang.stream.validation.required.programmeName }}</small
            >
          </div>
          <div class="form-group" :class="{ 'form-group--error': $v.stream.name.$error }">
            <label class="form__label">{{ $lang.stream.form.name }}
              <required_span/>
            </label>
            <input
                :maxlength="maxlength.name"
                :placeholder="$lang.stream.form.name"
                class="form-control"
                v-model="stream.name"/>
            <small
                class="error"
                v-if="$v.stream.name.$error && !$v.stream.name.required"
            >{{ $lang.stream.validation.required.name }}</small>
            <small
                class="error"
                v-if="$v.stream.name.$error && !$v.stream.name.minLength">
              {{ $lang.stream.validation.minLength.name }}
              {{ $v.stream.name.$params.minLength.min }}
              {{ $lang.stream.validation.common.letter }}
            </small>
          </div>
          <div class="form-group" :class="{ 'form-group--error': '' }">
            <label class="form__label">{{ $lang.stream.form.isActive }} </label>
            <input
                type="checkbox"
                :placeholder="$lang.stream.form.isActive"
                :value="1"
                @change="changedIsActive"
                v-model="stream.isActive"
            />
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton
              type="submit"
              size="sm"
              color="primary"
              :disabled="submitted">
            <CIcon name="cil-check-circle"/>
            {{ $lang.buttons.general.crud.submit }}
          </CButton>
        </CCardFooter>
      </CForm>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none">Discard</CButton>
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none">
          Accept
        </CButton>
      </template>
    </CModal>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.stream.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton
                    color="primary"
                    v-on:click="createTableRow"
                    v-if="checkPermission('create-stream')"
                    style="margin-right: 10px">
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Stream')">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert
              :color="messageColor"
              closeButton
              :show.sync="dismissCountDownS"
              fade>{{ alertMessage }}
          </CAlert>
          <v-server-table
              :columns="columns"
              url="/stream/list"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'Stream')">
            <template #status="data">
              <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
            </template>
            <template #name="data">
              <span :title="data.row.name">{{
                  trimfunction(data.row.name, 20)
                }}</span>
            </template>
            <template #programme="data">
              <span :title="data.row.programme.name">{{
                  trimfunction(data.row.programme.name, 20)
                }}</span>
            </template>
            <template #actions="data">
              <CButton
                  color="success"
                  v-c-tooltip="$lang.buttons.general.crud.edit"
                  v-on:click="editTableRow(data.row.id)"
                  v-if="checkPermission('edit-stream')">
                <i class="fas fa-pencil-alt"></i>
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('delete-stream')">
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {stream, programme} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {required, minLength} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import {Maxlength} from "../../store/maxlength";
Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "Stream",
  mixins: [Mixin],
  components: {
    required_span,
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      messageColor: "success",
      json_title: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDownS: 0,
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      ProgrammeOptions: [],
      ProgrammeModalOptions: [],
      self: this,
      module: stream,
      ProgrammeModule: programme,
      stream: {
        name: "",
        programmeName: "",
        isActive: 1,
      },
      maxlength: {
        name: Maxlength.stream.name,
      },
      columns: ["programme", "name", "status"],
      data: [],
      options: {
        headings: {
          programme: this.$lang.stream.table.programmeName,
          name: this.$lang.stream.table.name,
          status: this.$lang.stream.table.isActive,
        },
        editableColumns: ["programme", "name"],
        sortable: ["programme", "name"],
        filterable: ["programme", "name"],
      },
    };
  },
  validations: {
    stream: {
      name: {
        required,
        minLength: minLength(1),
      },
      programmeName: {
        required,
      },
    },
  },
  created() {
    if (
        this.checkPermission("edit-stream") === true ||
        this.checkPermission("delete-stream") === true
    ) {
      this.columns.push("actions");
    }
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    axios.get(this.listUrlApi(this.ProgrammeModule)).then((response) => {
      response.data.data.map(function (value, key) {
        self.ProgrammeOptions.push({value: value.id, label: value.name});
        self.ProgrammeModalOptions.push({value: value.id, label: value.name});
      });
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.stream.isActive = 1;
      } else {
        self.stream.isActive = 0;
      }
    },
    loadData() {
      let self = this;
      axios
          .get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response.status === 200) {
              self.data = [];
              self.data = response.data.data;
            }
          });
    },
    applyFilter() {
      let self = this;
      const reqObj = {
        programmeId: self.stream.programmeName.value,
      };
      store.commit("showLoader", true); // Loader Off
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      store.commit("showLoader", true); // Loader Off
      this.stream.programmeName = {};
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.stream.name = "";
      self.stream.programmeId = "";
      self.largeModal = true;
    },
    editTableRow(item) {
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      const id = item;
      self.editId = item;
      axios
          .get(this.viewUrlApi(this.module, id), self.model)
          .then(function (response) {
            if (response) {
              const responseData = response.data.data;
              self.stream.name = responseData.name;
              self.stream.isActive = responseData.isActive;
              self.stream.programmeName = {
                value: responseData.programme.id,
                label: responseData.programme.name,
              };
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                const responseData = response.data;
                if (responseData.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.dismissCountDownS = 10;
                  self.messageColor = "success";
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
    onSubmit() {
      let self = this;
      this.$v.stream.$touch();
      if (this.$v.stream.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            const postData = {
              name: self.stream.name,
              programmeId: self.stream.programmeName.value,
              isActive: self.stream.isActive,
            };
            axios
                .post(this.updateUrlweb(this.module, self.editId), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.messageColor = "success";
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            const postData = {
              name: self.stream.name,
              programmeId: self.stream.programmeName.value,
              isActive: self.stream.isActive,
            };
            axios
                .post(this.createUrlWeb(this.module), postData)
                .then(function (response) {
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.messageColor = "success";
                    self.$refs.myTable.refresh();
                    self.submitted = false; //Enable Button
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                    self.submitted = false; //Enable Button
                  }
                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        }
      }
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
